<template>
  <v-container fluid>
    <base-material-card
      icon="fa fa-truck"
      title="Reporte Almacenes de Vendedores"
      class="px-5 py-3"
    >
      <v-row>
        <v-col cols="6">
          <custom-select
            url="/vendedores/all"
            text="razon_social"
            label="Vendedor"
            @change="
              (value) => {
                form.vendedor_id = value;
                reset();
              }
            "
            :valor="form.vendedor_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="6">
          <custom-select
            url="/almacenes/tipos"
            text="nombre"
            label="Tipo de Almacen"
            @change="
              (value) => {
                form.almacenes_tipo_id = value;
                reset();
              }
            "
            :valor="form.almacenes_tipo_id"
            :first-select="true"
            first-text="Todos"
          ></custom-select>
        </v-col>
        <v-col cols="4" v-if="false">
          <custom-select
            url="/productos"
            :paginate="true"
            label="Producto"
            placeholder="Seleccione un producto"
            :valor="form.producto_id"
            @change="(value) => (form.producto_id = value)"
          ></custom-select>
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" @click="downloadPdf">Descargar PDF</v-btn>
          <v-btn color="green" @click="downloadExcel">Descargar Excel</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="facturas"
            :loading="loading"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.precio="{ item }">
              {{ formatoMoneda(item.producto.precio_default.precio) }}
            </template>
            <template v-slot:item.subtotal="{ item }">
              {{ formatoMoneda(calcularSubtotal(item)) }}
            </template>
            <template v-slot:item.impuestos="{ item }">
              {{ formatoMoneda(calcularImpuestos(item)) }}
            </template>
            <template v-slot:item.total="{ item }">
              {{
                formatoMoneda(calcularSubtotal(item) + calcularImpuestos(item))
              }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn fab icon x-small v-on="on" @click="openModal(item)">
                    <v-icon>fas fa-minus-circle</v-icon>
                  </v-btn>
                </template>
                <span>Restar de almacén</span>
              </v-tooltip>
            </template>
            <template v-slot:body.append>
              <tr>
                <td class="font-weight-bold">Numero Piezas</td>
                <td class="font-weight-bold">{{ total }}</td>
                <td colspan="4"></td>
                <td class="font-weight-bold">Subtotal</td>
                <td class="font-weight-bold">
                  {{
                    totales ? formatoMoneda(totales.subtotal) : formatoMoneda(0)
                  }}
                </td>
              </tr>
              <tr v-for="(impuesto, title) in totales.impuestos" :key="title">
                <td colspan="2"></td>
                <td class="font-weight-bold"></td>
                <td class="font-weight-bold"></td>
                <td colspan="2"></td>
                <td class="font-weight-bold">{{ title }}</td>
                <td class="font-weight-bold">
                  {{ formatoMoneda(impuesto) }}
                </td>
              </tr>
              <tr>
                <td colspan="2"></td>
                <td class="font-weight-bold"></td>
                <td class="font-weight-bold"></td>
                <td colspan="2"></td>
                <td class="font-weight-bold">Total</td>
                <td class="font-weight-bold">
                  {{
                    totales ? formatoMoneda(totales.total) : formatoMoneda(0)
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
        <v-col col="12">
          <v-pagination
            v-model="form.page"
            class="my-4"
            :length="form.last_page"
            @input="load"
            :total-visible="10"
            color="primary"
          ></v-pagination>
        </v-col>
      </v-row>
    </base-material-card>
  </v-container>
</template>
<script>
import CustomSelect from "@views/dashboard/component/CustomSelect.vue";

export default {
  components: {
    CustomSelect,
  },
  data: () => ({
    errors: {},
    headers: [
      { text: "Tipo Almacen", value: "almacen_tipo.nombre", sortable: false },
      { text: "Vendedor", value: "vendedor.razon_social", sortable: false },
      { text: "Producto", value: "producto.nombre", sortable: false },
      { text: "Precio", value: "precio", sortable: false },
      { text: "Cantidad", value: "cantidad", sortable: false },
      { text: "Subtotal", value: "subtotal", sortable: false },
      { text: "Impuestos", value: "impuestos", sortable: false },
      { text: "Total", value: "total", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    form: {
      vendedor_id: "",
      almacenes_tipo_id: "",
      producto_id: "",
      last_page: 1,
      page: 1,
    },
    loading: false,
    facturas: [],
    total: 0,
    totales: {
      impuestos: [],
    },
  }),
  mounted() {
    this.load();
  },
  methods: {
	openModal(item){
		
	},
    calcularSubtotal(item) {
      return item.producto.precio_default.precio * item.cantidad;
    },
    calcularImpuestos(item) {
      const subtotal = this.calcularSubtotal(item);
      return item.producto.impuestos.reduce(
        (total, impuesto) => total + subtotal * (impuesto.porcentaje / 100),
        0
      );
    },
    reset() {
      this.form.page = 1;
      this.load();
    },
    load() {
      this.loading = true;
      this.facturas = [];
      this.totales = {
        impuestos: [],
      };
      this.$http
        .get("/almacenes/vendedores", {
          params: this.form,
        })
        .then((response) => {
          this.facturas = response.data.data;
          this.total = response.data.cantidad_sum;
          this.totales = response.data.totales;
          this.form.last_page = response.data.last_page;
          this.form.page = response.data.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    downloadPdf() {
      this.$http({
        url: `/almacenes/vendedores/pdf`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteAlmacenesVendedores.pdf`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    downloadExcel(item) {
      this.$http({
        url: `/almacenes/vendedores/excel`,
        method: "GET",
        responseType: "blob", // important
        params: this.form,
      }).then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `ReporteAlmacenesVendedores.xlsx`); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    retryDownloadPdf(item) {
      this.$http
        .patch(`/facturas/${item.id}/retry-download`)
        .then((response) => {
          this.$store.commit("SET_SNACKBAR", {
            text: "Solicitud de generación de PDF exitosa; intente descarga más tarde",
            color: "success",
          });
        });
    },
  },
};
</script>
